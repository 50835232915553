export const vars = {
  primaryFont: "Figtree, sans-serif",
  white: '#FFFFFF',

  grey200: '#EAECF0',
  grey400: '#98A2B3',
  grey500: '#667085',
  grey600: '#475467',
  grey700: '#344054',
  grey800: '#1D2939',
  grey900: '#101828',
  grey50:'#F9FAFB',

  primary800: '#05796B',
  primary500: '#10B881',
  primary600: '#0BA47D',
  primary700: '#088E75',
  primary200: '#83DCB2',
  primary50: '#D1F2DF',
  primary25: '#F8FDFA',

  error700: '#B42318',
  error600: '#D92D20',
  error500: '#F04438',
  error25: '#FFFBFA',

  warning25: '#FFFCF5',
  warning50: '#FFFAEB',
  warning700: '#B54708',
  warning500: '#F79009',
  warning300: '#FEC84B',

  success50: '#ECFDF3',
  success700: '#027A48',
  success500: '#12B76A',
  checkboxBorderColor: '#D0D5DD',
  checkboxBgChecked: '#AAE7C7',
  dialogBoxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  cardBorderColor: '#83DCB2',
  cardBgColor: '#F8FDFA',
  cardChipBgColor: '#F2F4F7',
  iconButtonHoverShadow: '0rem 0.0625rem 0.125rem 0rem #1018280D',
  iconButtonFocusShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05), 0rem 0rem 0rem 0.25rem #F2F4F7'
};
